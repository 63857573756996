import './bootstrap';
import { createInertiaApp } from '@inertiajs/svelte';
import * as Sentry from '@sentry/svelte';
import Layout from './App.svelte';

// todo @ elia: remove preline stuff when replaced with shadcn
import { Inertia } from '@inertiajs/inertia';
import '/public/css/app.css';
import '/public/css/global.css';

import Sidebar from '@/Components/Sidebar.svelte';

document.addEventListener('DOMContentLoaded', () => {
    const sidebarMount = document.getElementById('svelte-sidebar-mount');

    if (sidebarMount) {
        new Sidebar({
            target: sidebarMount,
            props: {
                username: sidebarMount.dataset.username,
                userHandle: sidebarMount.dataset.userHandle,
            },
        });
    }
});

// Inertia.on('success', () => {
//     window.HSStaticMethods.autoInit();
// });
// Inertia.on('navigate', () => {
//     window.HSStaticMethods.autoInit();
// });

createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 250,

        // The color of the progress bar...
        color: '#6366f1',

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: false,
    },
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.svelte', { eager: true });
        let page = pages[`./Pages/${name}.svelte`];
        return { default: page.default, layout: page.layout || Layout };
    },
    setup({ el, App, props }) {
        Sentry.init({
            dsn: 'https://81ac49734d8d06fa7aabed8520b76673@o4507102522376192.ingest.de.sentry.io/4507113214902352',
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^http:\/\/127\.0\.0\.1/,
                /^https:\/\/staging.buymynudes.com\//,
                /^https:\/\/buymynudes.com\//,
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        new App({ target: el, props });
    },
});
